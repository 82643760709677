<template lang="html">
  <div class="notification" :class="[{ dark, clickable: content.link || (type === 'event' && $route.name !== 'events') }, type]" :style="{ transform: transformX !== 0 ? `translateX(${transformX}%)` : '', transition: transitionEnabled ? 'transform 200ms ease-out' : '' }" :tabindex="content.link ? 0 : ''" @click="handleClick" @mousedown="swipeStart" @touchstart="swipeStart" @touchend="swipeEnd" @touchmove="swipeUpdate">
    <div class="info">
      <img v-if="!['psa', 'prompt'].includes(type)" :src="author.avatar || '/img/default-avatar.png'" alt="no avatar found">
      <USIcon v-else :icon="icon" />
      <section>
        <span v-if="!['applicant', 'psa', 'prompt'].includes(type)" class="info-text">{{author.name | firstName}} {{infoText}}</span>
        <span v-else class="info-text">{{infoText}}</span>
        <span v-if="type === 'psa'" v-html="$options.filters.markdown(content.text, true)" />
        <span v-else-if="type === 'applicant'">{{ author.name | capitalize }}</span>
        <span v-else>{{targetLabel}}</span>
      </section>
      <USIcon v-if="!['psa', 'prompt'].includes(type)" :icon="icon" />
    </div>
    <p v-if="type === 'applicant'" v-html="$options.filters.markdown(content.text, true)" />
    <footer v-if="['applicant', 'event'].includes(type)">
      <USButton :color="type === 'applicant' ? 'danger' : null" :dark="dark" :primary="type === 'applicant' ? true : false" ref="declineButton" @click="handleDeclineClick">{{ type === 'applicant' ? 'Decline' : 'Maybe'}}</USButton>
      <USButton color="positive" :dark="dark" primary ref="acceptButton" @click="handleAcceptClick">{{ type === 'applicant' ? 'Accept' : 'Going'}}</USButton>
    </footer>
    <div v-if="content.link" class="ink" />
  </div>
</template>

<script>
import { Date as SugarDate } from 'sugar-date';

import capitalize from '@/filters/capitalize';
import firstName from '@/filters/firstName';
import markdown from '@/filters/markdown';

export default {
  computed: {
    icon() {
      switch (this.type) {
        case 'applicant':
          return 'add-user';
        case 'comment':
          return 'add-comment';
        case 'comment-like':
          return 'like-comment';
        case 'comment-reply':
          return 'reply-comment';
        case 'event':
          return 'add-calendar';
        case 'event-cancel':
          return 'cancel-calendar';
        case 'event-uncancel':
          return 'check-calendar';
        case 'favorite':
          return 'add-star';
        case 'like':
          return 'add-heart';
        case 'prompt':
          return 'writing-prompt';
        case 'psa':
          return 'announcement';
        case 'thread':
          return 'add-forum';
        case 'thread-reply':
          return 'reply-forum';
        default:
          return 'untold-stories';
      }
    },
    infoText() {
      switch (this.type) {
        case 'applicant':
          return 'New Applicant';
        case 'comment':
          return 'commented';
        case 'comment-like':
          return 'liked';
        case 'comment-reply':
          return 'replied to';
        case 'event':
          return 'added event';
        case 'event-cancel':
          return 'cancelled';
        case 'event-uncancel':
          return 'reinstated';
        case 'favorite':
          return 'favourited';
        case 'like':
          return 'liked';
        case 'prompt':
          return 'New Writing Prompt';
        case 'psa':
          return `PSA from ${new SugarDate(this.created).format('{HH}:{mm}')}`;
        case 'thread':
          return 'created';
        case 'thread-reply':
          return 'replied in';
        default:
          return 'Something’s Wrong';
      }
    },
    targetLabel() {
      if (this.type === 'comment-like' || this.type === 'comment-reply') return 'Your Comment';
      return this.content.targetLabel;
    },
  },
  data() {
    return {
      maxSwipeDistance: null,
      swiping: false,
      touchStartX: null,
      transformX: 0,
      transitionEnabled: false,
    };
  },
  filters: {
    capitalize,
    firstName,
    markdown,
  },
  methods: {
    handleAcceptClick() {
      if (this.type === 'applicant') this.$emit('accept', this.author._id);
      else {
        this.$emit('going', this.content.eventId);
        this.$emit('remove', this.id);
      }
    },
    handleClick(e) {
      if (this.swiping) return;
      if (this.content.link) {
        this.$router.push(this.content.link);
        this.$emit('remove', this.id);
      }
      if (this.type === 'event' && this.$route.name !== 'events' && e.target !== this.$refs.acceptButton.$el && e.target !== this.$refs.declineButton.$el && !this.$refs.acceptButton.$el.contains(e.target) && !this.$refs.declineButton.$el.contains(e.target)) {
        this.$router.push({ name: 'events' });
        this.$emit('remove', this.id);
      }
    },
    handleDeclineClick() {
      if (this.type === 'applicant') this.$emit('decline', this.author._id);
      else {
        this.$emit('maybe', this.content.eventId);
        this.$emit('remove', this.id);
      }
    },
    handleSwipeEnd() {
      this.transitionEnabled = true;
      if (this.transformX >= 50) {
        this.transformX = 100;
        this.$emit('remove', this.id);
      } else this.transformX = 0;
      window.setTimeout(() => { this.swiping = false; }, 1); // to avoid firing a click while releasing the swipe
    },
    swipeEnd(e) {
      if (e.type === 'mouseup') {
        window.removeEventListener('mousemove', this.swipeUpdate, false);
        window.removeEventListener('mouseup', this.swipeEnd, false);
      }
      this.handleSwipeEnd();
    },
    swipeStart(e) {
      if (this.transitionEnabled) this.transitionEnabled = false;
      this.touchStartX = e.type === 'touchstart' ? e.changedTouches[0].clientX : e.clientX;
      this.maxSwipeDistance = window.innerWidth - this.touchStartX;
      if (e.type === 'mousedown') {
        window.addEventListener('mousemove', this.swipeUpdate, false);
        window.addEventListener('mouseup', this.swipeEnd, false);
      }
    },
    swipeUpdate(e) {
      if (!this.swiping) this.swiping = true;
      const currentX = e.type === 'touchmove' ? e.changedTouches[0].clientX : e.clientX;
      const distance = currentX - this.touchStartX;

      if (distance > 0) this.transformX = Math.min((distance / this.maxSwipeDistance) * 100, 100);
      else this.transformX = 0;
    },
  },
  props: {
    id: String,
    author: {
      type: Object,
      default() {
        return {};
      },
    },
    content: {
      type: Object,
      default() {
        return {};
      },
    },
    created: Number,
    dark: Boolean,
    type: {
      type: String,
      validator: (v) => ['applicant', 'comment', 'comment-like', 'comment-reply', 'event', 'event-cancel', 'event-uncancel', 'favorite', 'like', 'prompt', 'psa', 'thread', 'thread-reply'].includes(v),
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/colors'
@require '../styles/shadows'

.notification
  padding: 1rem
  border: 1px solid $interactable
  border-radius: 0.75rem
  max-width: 22.5rem
  position: relative
  overflow: hidden
  touch-action: pan-y

  &.dark
    border-color: $interactable-dark

    .info .info-text
      color: $text-secondary-dark

  &.clickable
    cursor: pointer
    box-shadow: $shadow-low
    transition: border-color 200ms ease, box-shadow 200ms ease

    &:hover,
    &:focus
      box-shadow: $shadow-high
      border-color: $accent-primary

    &:focus .ink
      transform: translateY(-50%) scale(1.4142135624)

  &.prompt,
  &.psa
    .info section
      width: calc(100% - 2.5rem)

  &.psa
    background-color: $warning
    color: $text-primary
    border-color: @background-color

    .info
      align-items: flex-start

      .icon
        margin-top: 0.375rem

      span
        white-space: normal

        &.info-text
          color: inherit
          white-space: nowrap

  .info
    display: flex
    align-items: center

    img
      margin: 0
      width: 2.5rem
      height: @width
      border-radius: 50%
      margin-right: 1rem
      flex-shrink: 0

    .icon
      &:first-child
        margin-right: 1rem

      &:last-child
        margin-left: 1rem

    section
      width: calc(100% - 6rem)

      span
        display: block
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

        &.info-text
          color: $text-secondary
          font-size: 0.875rem
          line-height: 0.875rem

  footer
    margin: -1rem
    margin-top: 1rem
    padding: 0.375rem
    padding-top: 0
    display: flex

    .button
      width: 100%
      border-radius: 0.375rem
      padding: calc(0.375rem - 1px) calc(1.5rem - 1px)

      &:first-child
        margin-right: 0.375rem

  .ink
    width: 100%
    border-radius: 50%
    background-color: alpha($accent-primary, 0.1)
    position: absolute
    left: 0
    top: 50%
    transform: translateY(-50%) scale(0)
    transition: transform 200ms ease

    &::before
      content: ''
      display: block
      padding-bottom: 100%
</style>
